<script lang="ts" setup>
import ImageType from '~/enums/image-type'
import helpers from '~/helpers'

const props = defineProps<{
  brandData: any
}>()

const imageTypes = [ImageType.Thumbnail144p, ImageType.Original]

const imageUrl = computed(() => {
  const imageUrls = helpers.getImageUrls(props.brandData.relationships.images.data)

  return imageUrls ? helpers.getImageUrl(imageUrls, imageTypes) : null
})
const name = computed(() => props.brandData.attributes.name)
const path = computed(() => helpers.getBrandPath(props.brandData))
</script>

<template>
  <NuxtLink :to="path">
    <div class="brand-card__image-inner flex justify-center bg-white">
      <img v-if="imageUrl" v-lazy="imageUrl" :alt="name" loading="lazy">
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
.brand-card__image-inner {
  border-radius: 12px;
  box-sizing: border-box;
  height: 120px;
  overflow: auto;
  padding: 12px;
  position: relative;

  & img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
