<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import BrandCard from '~/components/cards/BrandCard.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import Page from '~/enums/page'
import type { BrandData } from '~/types/brands'

const props = defineProps<{
  brandsData: BrandData[]
  title: string
}>()

const galleryOptions: SwiperOptions = {
  breakpoints: {
    1020: { slidesPerGroup: 1, slidesPerView: 6 },
    960: { slidesPerGroup: 1, slidesPerView: 5 },
    768: { slidesPerGroup: 1, slidesPerView: 4 },
    480: { slidesPerGroup: 1, slidesPerView: 3.3, spaceBetween: 16 },
    360: { slidesPerGroup: 1, slidesPerView: 2.3, spaceBetween: 16 },
    0: { slidesPerGroup: 1, slidesPerView: 1, spaceBetween: 16 }
  },
  lazy: true,
  spaceBetween: 24
}
const path = Page.Brands

const router = useRouter()
</script>

<template>
  <section v-if="brandsData.length">
    <div class="container">
      <Heading element="h2" :path="path" :title="title" title-class="h1">
        <template #buttons>
          <BasicButton
            :aria-label="title"
            color="blue"
            icon="outlined/east"
            is-icon-only
            is-small
            @click="router.push(path)"
          />
        </template>
      </Heading>
    </div>

    <Gallery :options="galleryOptions">
      <BrandCard v-for="x in brandsData" :key="x.id" :brand-data="x" class="swiper-slide max-6-slides" />
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
